'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Map001 = function Map001() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var map = false;
  var markers = [];
  var clinics = [];

  var getFiltersServices = function getFiltersServices() {
    var params = location.search.substr(1).split('&').map(function (item) {
      var tmp = item.split('=');
      return [tmp[0], tmp[1]];
    });
    var services = [];

    params.forEach(function (param) {
      if (param[0] === 'services%5B%5D') {
        services.push(param[1]);
      }
    });

    return services;
  };

  var reorderClinic = function reorderClinic() {
    var $clinics = [].slice.call(document.querySelectorAll('.map001 .list-container .clinic-item'));
    if ($clinics.length) {
      var orderedClinics = [];
      var filteredServices = getFiltersServices();

      $clinics.forEach(function ($clinic) {
        var clinic = clinics.find(function (clinic) {
          return clinic.id === parseInt($clinic.dataset.id);
        });
        var distanceSpan = $clinic.querySelector('.clinic-distance span');
        var distance = 999999999;
        if (distanceSpan) {
          distance = parseInt(distanceSpan.textContent, 10);
        }

        var filterDisplay = true;

        if (filteredServices.length) {
          filterDisplay = false;
          var clinicsServices = $clinic.dataset.services.split('|');

          if (clinicsServices.length) {
            clinicsServices.forEach(function (clinicsService) {
              if (filteredServices.includes(clinicsService)) {
                filterDisplay = true;
              }
            });
          }
        }

        if (!filterDisplay) {
          $clinic.classList.add('without-arrow');
        }

        orderedClinics.push({
          clinic: $clinic,
          distance: distance,
          lat: clinic.lat,
          lng: clinic.lng,
          filterDisplay: filterDisplay
        });
      });

      orderedClinics.sort(function (a, b) {
        return a.distance - b.distance;
      });

      var firstDisplayedClinic = orderedClinics.find(function (el) {
        return el.filterDisplay;
      });

      if (firstDisplayedClinic) {
        map.setCenter(new google.maps.LatLng(parseFloat(firstDisplayedClinic.lat), parseFloat(firstDisplayedClinic.lng)));
        map.setZoom(15);

        var $container = document.querySelector('.map001 .list-container');
        $container.innerHTML = '';
        orderedClinics.forEach(function (orderedClinic) {
          $container.append(orderedClinic.clinic);
        });
      }

      var $button = document.querySelector('.map001 .postal-code-form .form-button');
      $button.classList.remove('is-loading');
    }
  };

  var getDistance = function getDistance(origin) {
    clinics.forEach(function (clinic) {
      // eslint-disable-next-line max-len
      var distance = Math.floor(window.site.PostalCodes.calculateDistance(origin.lat, origin.lng, clinic.lat, clinic.lng));
      clinic.distance = distance;

      var $clinic = document.querySelector('.map001 .list-container .clinic-item[data-id="' + clinic.id + '"]');
      $clinic.querySelector('.clinic-distance span').textContent = distance;
      $clinic.querySelector('.clinic-distance').classList.add('is-active');
    });

    reorderClinic();
  };

  var showPosition = function showPosition(position) {
    getDistance({ lat: position.coords.latitude, lng: position.coords.longitude });
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    // Filters
    var $filtersOpener = document.querySelector('.map001-filters-button');
    if ($filtersOpener) {
      var $filtersForm = document.querySelector('.map001-filters-wrapper');
      var $checkboxes = $filtersForm.querySelectorAll('.filter-item .filter-input');

      $filtersOpener.addEventListener('click', function (e) {
        e.preventDefault();

        $filtersForm.classList.add('is-active');
      });

      // Close Filters Form
      var closeFilters = function closeFilters() {
        $filtersForm.classList.remove('is-active');
      };

      var $filtersFormCloseTriggers = document.querySelectorAll('.filters-overlay, .filters-close');
      if ($filtersFormCloseTriggers.length) {
        $filtersFormCloseTriggers.forEach(function ($filtersFormCloseTrigger) {
          $filtersFormCloseTrigger.addEventListener('click', function (e) {
            e.preventDefault();
            closeFilters();
          });
        });
      }

      // Filters Reset
      var $filtersResetButton = document.querySelector('.filter-reset');
      $filtersResetButton.addEventListener('click', function (e) {
        e.preventDefault();

        if ($checkboxes.length) {
          $checkboxes.forEach(function ($checkbox) {
            // eslint-disable-next-line no-param-reassign
            $checkbox.checked = false;
          });
          $filtersResetButton.classList.add('is-inactive');
        }
      });

      // Filters Reset Style
      var checkCheckboxesStatus = function checkCheckboxesStatus() {
        if ($checkboxes.length) {
          var statusAllInactive = $checkboxes.every(function ($checkbox) {
            return !$checkbox.checked;
          });

          if (statusAllInactive) {
            $filtersResetButton.classList.add('is-inactive');
          } else {
            $filtersResetButton.classList.remove('is-inactive');
          }
        }
      };

      if ($checkboxes.length) {
        $checkboxes.forEach(function ($checkbox) {
          $checkbox.addEventListener('change', function () {
            checkCheckboxesStatus();
          });
        });
      }
      checkCheckboxesStatus();
    }

    var $postalForm = document.querySelector('.map001 .postal-code-form');
    if ($postalForm) {
      $postalForm.addEventListener('submit', function (e) {
        e.preventDefault();

        var $input = $postalForm.querySelector('.form-input');
        var $error = $postalForm.querySelector('.errors.for-input');
        $error.classList.add('is-hidden');
        var regex = /^[A-Za-z]\d[A-Za-z].?.?.?.?$/;
        var validationTest = String($input.value).toLowerCase().match(regex);

        if (!validationTest) {
          $error.classList.remove('is-hidden');
          e.stopPropagation();
          return false;
        } else if (!map) {
          alert('The map is not ready, try later.');
        } else {
          var postalCode = $input.value.replaceAll(' ', '');

          // Replace value on the other form
          var $otherFormInput = document.querySelector('.map001-filters-wrapper .hidden-postal-code-input');
          if ($otherFormInput) {
            $otherFormInput.value = postalCode;
          }

          var params = location.search.substr(1).split('&').map(function (item) {
            var tmp = item.split('=');
            return [tmp[0], tmp[1]];
          });

          params = params.map(function (item) {
            var returnObject = [item[0], item[1]];
            if (returnObject[0] === 'postalCode') {
              returnObject[1] = postalCode;
            }

            return returnObject.join('=');
          });

          window.history.replaceState(null, null, '?' + params.join('&'));

          var $button = document.querySelector('.map001 .postal-code-form .form-button');
          $button.classList.add('is-loading');

          window.site.PostalCodes.getCoordinates(postalCode, getDistance);
        }
      });
    }

    inited = true;

    // Return success
    return true;
  };

  var initMap = function initMap() {
    var $map = document.querySelector('.map001 .map-list-container #map');

    clinics = JSON.parse($map.dataset.clinics);

    var bounds = new google.maps.LatLngBounds();

    // Init map
    map = new google.maps.Map($map);

    clinics.forEach(function (clinic) {
      var latLng = { lat: parseFloat(clinic.lat), lng: parseFloat(clinic.lng) };
      var marker = new google.maps.Marker({
        map: map,
        position: latLng,
        url: clinic.url,
        draggable: false,
        icon: { url: 'data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyOCI+PHBhdGggZD0iTTEwIC42NjdBOS4xNCA5LjE0IDAgMDAuODMxIDkuODM0YzAgNS4wODMgOS4xNjcgMTcuNSA5LjE2NyAxNy41czkuMTY3LTEyLjQxNyA5LjE2Ny0xNy41QTkuMTQgOS4xNCAwIDAwMTAgLjY2N3pNMTAgMTRhNC4xMjcgNC4xMjcgMCAwMS00LjE2OC00LjE2NyA0LjEyNyA0LjEyNyAwIDAxNC4xNjctNC4xNjcgNC4xMjcgNC4xMjcgMCAwMTQuMTY3IDQuMTY3QTQuMTI3IDQuMTI3IDAgMDExMCAxNHoiIGZpbGw9IiMwMEJDRTciLz48L3N2Zz4=', scaledSize: new google.maps.Size(40, 40) },
        optimized: false
      });
      var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(loc);

      map.fitBounds(bounds);
      map.panToBounds(bounds);

      google.maps.event.addListener(marker, 'click', function () {
        window.location.href = this.url;
      });

      markers.push(marker);
    });

    map.addListener('idle', function () {
      var bounds = map.getBounds();
      var visibleClinicsIds = [];
      markers.forEach(function (marker) {
        if (bounds.contains(marker.getPosition())) {
          var clinic = clinics.find(function (el) {
            return el.url === marker.url;
          });
          visibleClinicsIds.push(clinic.id);
        }
      });

      var $clinics = [].slice.call(document.querySelectorAll('.map001 .list-container .clinic-item'));
      if ($clinics.length) {
        $clinics.forEach(function ($clinic) {
          var id = parseInt($clinic.dataset.id);

          if (visibleClinicsIds.includes(id)) {
            $clinic.classList.remove('is-hidden');
          } else {
            $clinic.classList.add('is-hidden');
          }
        });

        var $activeClinics = [].slice.call(document.querySelectorAll('.map001 .list-container .clinic-item:not(.is-hidden)'));
        if (!$activeClinics.length) {
          $clinics.forEach(function ($clinic) {
            $clinic.classList.remove('is-hidden');
          });
        }
      }
    });

    var postalCode = document.querySelector('.map001 .postal-code-form .form-input');
    if (postalCode && postalCode.value.length) {
      var $button = document.querySelector('.map001 .postal-code-form button');
      $button.click();
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  };

  // Expose public methods & properties
  return {
    init: init,
    initMap: initMap
  };
}();